import "./index.scss";
import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import { SplashScreen } from "@feltapp/metronic/partials/controls";

const App = lazy(() => import("./app/App"));

ReactDOM.render(
  <Suspense fallback={<SplashScreen />}>
    <App />
  </Suspense>,
  document.getElementById("root")
);
